import { LanguageInterface } from "./language-interface";

export const Language: LanguageInterface[] = [
  {
    dashboard: "DOCTOR.DASHBOARD-PAGE-DASHBOARD",
    home: "DOCTOR.DASHBOARD-PAGE-HOME",
    doctorDashboard: "DOCTOR.DASHBOARD-PAGE-DOCTOR-DASHBOARD",

    allAppointments: "DOCTOR.APPOINMENTS-PAGE-ALL-APPOINMENTS",
    appointments: "DOCTOR.APPOINMENTS-PAGE-APPOINMENTS",

    calendar: "DOCTOR.CALENDAR-PAGE-CALENDAR",

    totalReport: "DOCTOR.DASHBOARD.TOTELREPORT",
    totalIncome: "DOCTOR.DASHBOARD.TOTELINCOME",
    totalcase: "DOCTOR.DASHBOARD.TOTALCASE",
    totaldoctorrevenuetotaled: "DOCTOR.DASHBOARD.TOTALDOCTORREVENUETOTALED",
    baht: "DOCTOR.DASHBOARD.BAHT",
    casedoctortotalrevenuetotaled:
      "DOCTOR.DASHBOARD.CASEDOCTORTOTALREVENUETOTALED",
    case: "DOCTOR.DASHBOARD.CASE",
    rating: "DOCTOR.DASHBOARD.RATING",
    ratings: "DOCTOR.DASHBOARD.RATINGS",
    casein: "DOCTOR.DASHBOARD.CASEIN",
    lastincomereport: "DOCTOR.DASHBOARD.LASTINCOMEREPORT",
    totaltoday: "DOCTOR.DASHBOARD.TOTALTODAY",
    totalweek: "DOCTOR.DASHBOARD.TOTALWEEK",
    totalmonth: "DOCTOR.DASHBOARD.TOTALMONTH",
    are: "DOCTOR.DASHBOARD.ARE",
    weekly: "DOCTOR.DASHBOARD.WEEKLY",
    totelincomeof: "DOCTOR.DASHBOARD.TOTELINCOMEOF",
    barcharttotalincome: "DOCTOR.DASHBOARD.BARCHARTTOTALINCOME",
    casedoctorreport: "DOCTOR.DASHBOARD.CASEDOCTORREPORT",
    casetoday: "DOCTOR.DASHBOARD.CASETODAY",
    caseweek: "DOCTOR.DASHBOARD.CASEWEEK",
    casemonth: "DOCTOR.DASHBOARD.CASEMONTH",
    totalcaseof: "DOCTOR.DASHBOARD.TOTALCASEOF",
    barchartdoctorcase: "DOCTOR.DASHBOARD.BARCHARTDOCTORCASE",
    next: "ADMIN.ALLDOCTORS.NEXT",
    previous: "ADMIN.ALLDOCTORS.PREVIOUS",
    pagepage: "ADMIN.ALLDOCTORS.PAGEPAGE",
    pageof: "ADMIN.ALLDOCTORS.PAGEOF",

    listappointments: "DOCTOR.APPOINTMENTSREQUEST.LISTAPPOINTMENTS",
    earlysymptoms: "DOCTOR.APPOINTMENTSREQUEST.EARLYSYMPTOMS",
    pictureearlysymptoms: "DOCTOR.APPOINTMENTSREQUEST.PICTUREEARLYSYMPTOMS",
    chat: "DOCTOR.APPOINTMENTSREQUEST.CHAT",
    videochat: "DOCTOR.APPOINTMENTSREQUEST.VIDEOCHAT",
    appointment: "DOCTOR.APPOINTMENTSREQUEST.APPOINTMENT",
    atthistime: "DOCTOR.APPOINTMENTSREQUEST.ATTHISTIME",
    check: "DOCTOR.APPOINTMENTSREQUEST.CHECK",
    close: "DOCTOR.APPOINTMENTSREQUEST.CLOSE",
    waitingforpayment: "DOCTOR.APPOINTMENTSREQUEST.WAITINGFORPAYMENT",
    successfulappointment: "DOCTOR.APPOINTMENTSREQUEST.SUCCESSFULAPPOINTMENT",
    nodata: "DOCTOR.APPOINTMENTSREQUEST.NODATA",
    healthinformation: "DOCTOR.APPOINTMENTSREQUEST.HEALTHINFORMATION",
    patient: "DOCTOR.APPOINTMENTSREQUEST.PATIENT",
    initialsymptoms: "DOCTOR.APPOINTMENTSREQUEST.INITIALSYMPTOMS",
    symptoms: "DOCTOR.APPOINTMENTSREQUEST.SYMPTOMS",
    havesymptoms: "DOCTOR.APPOINTMENTSREQUEST.HAVESYMPTOMS",
    detail: "DOCTOR.APPOINTMENTSREQUEST.DETAIL",
    appointmentsrequest: "DOCTOR.APPOINTMENTSREQUEST.APPOINTMENTSREQUEST",
    consultationtime: "DOCTOR.APPOINTMENTSREQUEST.CONSULTATIONTIME",
    expenses: "DOCTOR.APPOINTMENTSREQUEST.Expenses",
    min: "DOCTOR.APPOINTMENTSREQUEST.MIN",
    dateappointment: "DOCTOR.APPOINTMENTSREQUEST.DATEAPPOINTMENT",
    detailuser: "DOCTOR.APPOINTMENTSREQUEST.DETAILUSER",
    email: "DOCTOR.APPOINTMENTSREQUEST.EMAIL",
    phone: "DOCTOR.APPOINTMENTSREQUEST.PHONE",
    heightweight: "DOCTOR.APPOINTMENTSREQUEST.HEIGHTWEIGHT",
    gender: "DOCTOR.APPOINTMENTSREQUEST.GENDER",
    age: "DOCTOR.APPOINTMENTSREQUEST.AGE",
    bloodtype: "DOCTOR.APPOINTMENTSREQUEST.BLOODTYPE",
    calendarsappointments: "DOCTOR.APPOINTMENTSCALENDAR.CALENDARSAPPOINTMENTS",
    sec: "DOCTOR.APPOINTMENTSCALENDAR.SEC",
    hour: "DOCTOR.APPOINTMENTSCALENDAR.HOUR",
    day: "DOCTOR.APPOINTMENTSCALENDAR.DAY",
    week: "DOCTOR.APPOINTMENTSCALENDAR.WEEK",
    month: "DOCTOR.APPOINTMENTSCALENDAR.MONTH",
    period: "DOCTOR.APPOINTMENTSCALENDAR.PERIOD",
    daymonthyear: "DOCTOR.APPOINTMENTSCALENDAR.DAY-MONTH-YEAR",

    datawidget: "DOCTOR.ARTICLES.DATAWIDGET",
    doctor: "DOCTOR.ARTICLES.DOCTOR",
    doctorarticles: "DOCTOR.ARTICLES.DOCTORARTICLES",
    articles: "DOCTOR.ARTICLES.ARTICLES",
    reviewers: "DOCTOR.ARTICLES.REVIEWER",
    composenewarticle: "DOCTOR.ARTICLES.COMPOSENEWARTICLE",
    heading: "DOCTOR.ARTICLES.HEADING",
    headingisrequired: "DOCTOR.ARTICLES.HEADINGISREQUIRED",
    postarticles: "DOCTOR.ARTICLES.POSTARTICLES",
    editarticle: "DOCTOR.ARTICLES.EDITARTICLE",
    articlesdoctor: "DOCTOR.ARTICLES.ARTICLESDOCTOR",

    patients: "DOCTOR.PATIENTS.PATIENTS",
    record: "DOCTOR.PATIENTS.RECORD",
    allrecord: "DOCTOR.PATIENTS.ALLRECORD",
    appointmentsid: "DOCTOR.PATIENTS.APPOINTMENTSID",
    date: "DOCTOR.PATIENTS.DATE",
    chatvideocall: "DOCTOR.PATIENTS.CHATVIDEOCALL",
    datail: "DOCTOR.PATIENTS.DATAIL",
    status: "DOCTOR.PATIENTS.STATUS",
    cancel: "DOCTOR.PATIENTS.CANCEL",
    readmore: "DOCTOR.PATIENTS.READMORE",
    loading: "DOCTOR.PATIENTS.LOADING",
    patientprofile: "DOCTOR.PATIENTS.PATIENTPROFILE",
    profile: "DOCTOR.PATIENTS.PROFILE",
    year: "DOCTOR.PATIENTS.YEAR",
    kg: "DOCTOR.PATIENTS.KG",
    cm: "DOCTOR.PATIENTS.CM",
    bmi: "DOCTOR.PATIENTS.BMI",
    group: "DOCTOR.PATIENTS.GROUP",
    male: "DOCTOR.PATIENTS.MALE",
    female: "DOCTOR.PATIENTS.FEMALE",
    phonereletive: "DOCTOR.PATIENTS.PHONERELETIVE",
    address: "DOCTOR.PATIENTS.ADDRESS",
    search: "DOCTOR.PATIENTS.SEARCH",
    appointmentpatient: "DOCTOR.PATIENTS.APPOINTMENTPATIENT",
    total: "DOCTOR.PATIENTS.TOTAL",
    datapatient: "DOCTOR.PATIENTS.DATAPATIENT",
    time: "DOCTOR.PATIENTS.TIME",
    price: "DOCTOR.PATIENTS.PRICE",
    noapprop: "DOCTOR.PATIENTS.NOAPPROP",
    fromtype: "DOCTOR.PATIENTS.FROMTYPE",
    healthinfomation: "DOCTOR.PATIENTS.HEALTHINFOMATION",
    description: "DOCTOR.PATIENTS.DESCRIPTION",
    patientrequest: "DOCTOR.PATIENTS.PATIENTREQUEST",
    payment: "DOCTOR.PATIENTS.PAYMENT",
    statuspayment: "DOCTOR.PATIENTS.STATUSPAYMENT",
    noappropriation: "DOCTOR.PATIENTS.NOAPPROPRIATION",
    extime: "DOCTOR.PATIENTS.EXTIME",
    round: "MENUITEMS.DISTRIBUTOR.TIME",

    online: "DOCTOR.CHAT.ONLINE",
    historychat: "DOCTOR.CHAT.HISTORYCHAT",
    noresult: "DOCTOR.CHAT.NORESULT",
    messageisrequired: "DOCTOR.CHAT.MESSAGEREQUIRED",
    letsstarttheconversation: "DOCTOR.CHAT.LETSSTARTTHECONVERSATION",
    Pleaseselectthepersonyouwanttochatwith:
      "DOCTOR.CHAT.PLETSSTARTTHECONVERSATION",

    totalincome: "DOCTOR.TOTALINCOME.TOTALINCOME",
    monthincome: "DOCTOR.TOTALINCOME.MONTHINCOME",
    todaysincome: "DOCTOR.TOTALINCOME.TODAYSINCOME",
    netincome: "DOCTOR.TOTALINCOME.NETINCOME",
    incomeinformation: "DOCTOR.TOTALINCOME.INCOMEINFORMATION",
    barchart: "DOCTOR.TOTALINCOME.BARCHART",
    historytotalincome: "DOCTOR.TOTALINCOME.HISTORYTOTALINCOME",
    enteradaterange: "DOCTOR.TOTALINCOME.ENTERADATERANGE",

    invoice: "DOCTOR.TOTALINCOME.INVOICE",
    subtotalamount: "DOCTOR.TOTALINCOME.SUBTOTALAMOUNT",
    discount: "DOCTOR.TOTALINCOME.DISCOUNT",
    vat: "DOCTOR.TOTALINCOME.VAT",
    totals: "DOCTOR.TOTALINCOME.TOTAL",
    print: "DOCTOR.TOTALINCOME.PRINT",
    bill: "DOCTOR.TOTALINCOME.BILL",
    patientname: "DOCTOR.TOTALINCOME.PATIENTNAME",
    doctorname: "DOCTOR.TOTALINCOME.DOCTORSNAME",
    billingdateing: "DOCTOR.TOTALINCOME.BILLINGDATEING",
    list: "DOCTOR.TOTALINCOME.LIST",
    totalprice: "DOCTOR.TOTALINCOME.TOTALPRICES",
    prices: "DOCTOR.TOTALINCOME.PRICES",

    summarypage: "DOCTOR.MENU.SUMMARYPAGE",
    request: "DOCTOR.MENU.REQUEST",
    appointmentscalendar: "DOCTOR.MENU.APPOINTMENTSCALENDAR",
    artic: "DOCTOR.MENU.ARTICLE",
    people: "DOCTOR.MENU.PEOPLE",
    user: "DOCTOR.MENU.USER",
    today: "DOCTOR.MENU.TODAY",

    doctorprofile: "DOCTOR.PROFILE.DOCTORPROFILE",
    profiles: "DOCTOR.PROFILE.PROFILE",
    chatpertime: "DOCTOR.PROFILE.CHATPERTIME",
    videopertime: "DOCTOR.PROFILE.VIDEOPERTIME",
    language: "DOCTOR.PROFILE.LANGUAGE",
    thai: "DOCTOR.PROFILE.THAI",
    english: "DOCTOR.PROFILE.ENGLISH",
    japan: "DOCTOR.PROFILE.JAPAN",
    china: "DOCTOR.PROFILE.CHINA",
    khmer: "DOCTOR.PROFILE.KHMER",
    malay: "DOCTOR.PROFILE.MALAY",
    philippines: "DOCTOR.PROFILE.PHILIPPINES",
    myanmar: "DOCTOR.PROFILE.MYANMAR",
    laos: "DOCTOR.PROFILE.LAOS",
    vietnam: "DOCTOR.PROFILE.VIETNAM",
    indonesia: "DOCTOR.PROFILE.INDONESIA",
    noinformationworkingtime: "DOCTOR.PROFILE.NOINFORMATIONWORKINGTIME",
    noinformationskill: "DOCTOR.PROFILE.NOINFORMATIONSKILL",
    noinformationbank: "DOCTOR.PROFILE.NOINFORMATIONBANK",
    settings: "DOCTOR.PROFILE.SETTINGS",
    account: "DOCTOR.PROFILE.ACCOUNT",
    username: "DOCTOR.PROFILE.USERNAME",
    duplicateusername: "DOCTOR.PROFILE.DUPLICATEUSERNAME",
    pleaseenteravalidemailaddress:
      "DOCTOR.PROFILE.PLEASEENTERAVALIDEMAILADDRESS",
    duplicateemail: "DOCTOR.PROFILE.DUPLICATEEMAIL",
    enteryourpassword: "DOCTOR.PROFILE.ENTERYOURPASSWORD",
    confirmpassword: "DOCTOR.PROFILE.CONFIRMPASSWORD",
    passwordsarenotthesame: "DOCTOR.PROFILE.PASSWORDSARENOTTHESAME",
    prefix: "DOCTOR.PROFILE.PREFIX",
    firstname: "DOCTOR.PROFILE.FIRSTNAME",
    firstnameisrequired: "DOCTOR.PROFILE.FIRSTNAMEISREQUIRED",
    lastname: "DOCTOR.PROFILE.LASTNAME",
    lastnameisrequired: "DOCTOR.PROFILE.LASTNAMEISREQUIRED",
    selectgender: "DOCTOR.PROFILE.SELECTGENDER",
    ageisrequired: "DOCTOR.PROFILE.AGEISREQUIRED",
    chooseadate: "DOCTOR.PROFILE.CHOOSEADATE",
    birthofdateisrequired: "DOCTOR.PROFILE.BRITHOFDATEISREQUIRED",
    taxid: "DOCTOR.PROFILE.TAXID",
    taxidisrequired: "DOCTOR.PROFILE.TAXIDISREQUIRED",
    citizenid: "DOCTOR.PROFILE.CITIZENID",
    citizenidisrequired: "DOCTOR.PROFILE.CITIZENIDISREQUIRED",
    chattimeprice: "DOCTOR.PROFILE.CHATTIMEPRICE",
    chattimepriceisrequired: "DOCTOR.PROFILE.CHATTIMEPRICEISREQUIRED",
    videotimeprice: "DOCTOR.PROFILE.VIDEOTIMEPRICE",
    videotimepriceisrequired: "DOCTOR.PROFILE.VIDEOTIMEPRICEISREQUIRED",
    education: "DOCTOR.PROFILE.EDUCATION",
    pleaseenteravalideducation: "DOCTOR.PROFILE.PLEASEENTERAVALIDEDUCATION",
    workexperience: "DOCTOR.PROFILE.WORKEXPERIENCE",
    pleaseenteravalidworkexperience:
      "DOCTOR.PROFILE.PLEASEENTERAVALIDWORKEXPERIENCEYEAR",
    workplace: "DOCTOR.PROFILE.WORKPLACE",
    pleaseenteravalidworkplace: "DOCTOR.PROFILE.PLEASEENTERAVALIDWORKPLACE",
    languageproficiency: "DOCTOR.PROFILE.LANGUAGEPROFICIENCY",
    aboutdoctor: "DOCTOR.PROFILE.ABOUTDOCTOR",
    save: "DOCTOR.PROFILE.SAVE",
    workingtime: "DOCTOR.PROFILE.WORKINGTIME",
    skills: "DOCTOR.PROFILE.SKILLS",
    doctorskills: "DOCTOR.PROFILE.DOCTORSKILL",
    bank: "DOCTOR.PROFILE.BANK",
    bankname: "DOCTOR.PROFILE.BANKNAME",
    cardcode16digits: "DOCTOR.PROFILE.CARDCODE16DIGITS",
    cardcodeisrequired: "DOCTOR.PROFILE.CARDCODEISREQUIRED",
    duplicatecardcode: "DOCTOR.PROFILE.DUPLICATECARDCODE",
    accountname: "DOCTOR.PROFILE.ACCOUNTNAME",
    accountnameisrequired: "DOCTOR.PROFILE.ACCOUNTNAMEISREQUIRED",
    duplicateaccountname: "DOCTOR.PROFILE.DUPLICATEACCOUNTNAME",
    add: "DOCTOR.PROFILE.ADD",
    code: "DOCTOR.PROFILE.CODE",
    nodatapayment: "DOCTOR.PROFILE.NODATAPAYMENT",
    edit: "DOCTOR.PROFILE.DELETE",
    delete: "DOCTOR.PROFILE.EDIT",
    editaboutdoctor: "DOCTOR.PROFILE.EDITABOUTDOCTOR",
    editabout: "DOCTOR.PROFILE.EDITABOUT",
    skillnamethai: "DOCTOR.PROFILE.SKILLNAMETHAI",
    skillnameenglish: "DOCTOR.PROFILE.SKILLNAMEENGLISH",
    chooseanskill: "DOCTOR.PROFILE.CHOOSEANSKILL",
    timestart: "DOCTOR.PROFILE.TIMESTART",
    timeend: "DOCTOR.PROFILE.TIMEEND",
    choosedaystart: "DOCTOR.PROFILE.CHOOSEDAYSTART",
    choosedayend: "DOCTOR.PROFILE.CHOOSEDAYEND",
    sunday: "DOCTOR.PROFILE.SUNDAY",
    monday: "DOCTOR.PROFILE.MONDAY",
    tuesday: "DOCTOR.PROFILE.TUESDAY",
    wednesday: "DOCTOR.PROFILE.WEDNESDAY",
    thursday: "DOCTOR.PROFILE.THURSDAY",
    friday: "DOCTOR.PROFILE.FRIDAY",
    saturday: "DOCTOR.PROFILE.SATURDAY",
    workingtimeinformation: "DOCTOR.PROFILE.WORKINGTIMEINFORMATION",
    workingtimesettings: "DOCTOR.PROFILE.WORKINGTIMESETTINGS",
    skillsandworkingtime: "DOCTOR.PROFILE.SKILLSANDWORKINGTIME",
    shortdoctor: "DOCTOR.PROFILE.SHORTDOCTOR",

    patientinformation: "DOCTOR.CHAT.PATIENTINFORMATION",
    patientnamee: "DOCTOR.CHAT.PATIENTNAME",
    agee: "DOCTOR.CHAT.AGE",
    height: "DOCTOR.CHAT.HEIGHT",
    weight: "DOCTOR.CHAT.WEIGHT",
    bloodtypee: "DOCTOR.CHAT.BLOODTYPE",
    initialsymptomss: "DOCTOR.CHAT.INITIALSYMPTOMS",
    durationofsymptoms: "DOCTOR.CHAT.DURATIONOFSYMPTOMS",
    notehere: "DOCTOR.CHAT.NOTEHERE",
    allnotes: "DOCTOR.CHAT.ALLNOTES",
    note: "DOCTOR.CHAT.NOTE",
    newnote: "DOCTOR.CHAT.NEWNOTE",
    savee: "DOCTOR.CHAT.SAVE",

    // conversationnotification: "DOCTOR.CHAT.CONVERSATIONNOTIFICATION",
    // yourtalktimeisleft: "DOCTOR.CHAT.YOURTALKTIMEISLEFT",

    // talktimeisover: "DOCTOR.CHAT.TALKTIMEISOVER",
    // talkfor15minutes: "DOCTOR.CHAT.TALKFOR15MINUTES",

    confirm: "DOCTOR.CHAT.CONFIRM",


    patientsall: "DOCTOR.PATIENTSALL.PATIENTSALL",
    image: "ADMIN.ALLDOCTORS.IMAGE",
    doctorconfirmed: "DOCTOR.PATIENTSALL.DOCTORCON",
    doctorcancel: "DOCTOR.PATIENTSALL.DOCTORCAN",
    usercancel: "DOCTOR.PATIENTSALL.USERCAN",
    noteaccept: "DOCTOR.PATIENTSALL.NOTEACCEPT",
    no: "DOCTOR.PATIENTSALL.NO",

    dateOfBirth: "ADMIN.ALLDOCTORS.DATEOFBIRTH",
    editadmin: "ADMIN.SETTING.EDIT-ADMIN",

    changetime: "DOCTOR.APPOINTMENTSREQUEST.CHANGETIME",
    other: "DOCTOR.PROFILE.OTHER",
    update: "ADMIN.ALLDOCTORS.UPDATE",
    waitingforresponse: "AUDIT.ORDERHISTORYPAGE.WAITINGFORRESPONSE",

    requesttime: "DOCTOR.APPOINTMENTSREQUEST.REQUEST-APPOINMENTS-TIME",
    requesttimefrom: "DOCTOR.APPOINTMENTSREQUEST.REQUEST-APPOINMENTS-TIME-FROM",
    originaltime: "DOCTOR.APPOINTMENTSREQUEST.ORIGINAL-APPOINMENTS-TIME",
    newtime: "DOCTOR.APPOINTMENTSREQUEST.NEW-APPOINMENTS-TIME",
    canceltime: "DOCTOR.APPOINTMENTSREQUEST.CANCEL-REQUEST-CHANGETIME",
    all: "ADMIN.SETTING.All",
    unablereschedule: "DOCTOR.APPOINTMENTSREQUEST.UNABLE-RESCHEDULE",
    canpostponetime: "DOCTOR.APPOINTMENTSREQUEST.POSTPONE-TIME",

    name: "ADMIN.ALLDOCTORS.NAME",
    more: "AUDIT.USERPACKAGEACTIVATE.MOREMORE",
    nomatch: "ADMIN.ALLPACKAGE.NOMATCH",
    password: "MENUITEMS.DISTRIBUTOR.PASSWORD",
    confirmpasswordd: "MENUITEMS.DISTRIBUTOR.PASSWORDCONFIRM",
    deliver: "ADMIN.DELIVER.DELIVER",
    viewdeliver: "ADMIN.DELIVER.VIEW-DETAIL-DELIVER",
    listdeliver: "ADMIN.DELIVER.LIST-DELIVER",

    descriptions: "NUTRITIONIST.CONSULTPROFILE.DESCRIPTION",
    choosedescriptions: "NUTRITIONIST.CONSULTPROFILE.CHOOSE-DISCRIPTION",
    morefilters: "More filters",
    ddmmyy: "DD/MM/YY",

    unsendMessage: 'DOCTOR.CHAT.UNSENDMESSAGE',
    unsentMessage: 'DOCTOR.CHAT.UNSENTMESSAGE',
    actions: "ADMIN.ALLDOCTORS.ACTIONS",

    revenuethisyear: 'DOCTOR.TOTALINCOME.REVENUETHISYEAR',
    revenuethisweek: 'DOCTOR.TOTALINCOME.REVENUETHISMONTH',
    stickynotes: 'DOCTOR.CHAT.STICKYNOTES',
    welcomeback: 'DOCTOR.TOTALINCOME.WELCOMEBACK',
    gladtoseeyouagain: 'DOCTOR.TOTALINCOME.GLADTOSEEYOUAGAIN',
    askmeanything: 'DOCTOR.TOTALINCOME.ASKMEANYTHING',
    articleslike: 'DOCTOR.TOTALINCOME.ARTICLESLIKE',
    fromallarticles: 'DOCTOR.TOTALINCOME.FROMALLARTICLES',
    basedonlikes: 'DOCTOR.TOTALINCOME.BASEDONLIKES',
    comparedtolastmonth: 'DOCTOR.TOTALINCOME.COMPAREDTOLASTMONTH',
    requestssummary: 'DOCTOR.TOTALINCOME.REQUESTSSUMMARY',
    totalrequest: 'DOCTOR.TOTALINCOME.TOTALREQUEST',
    totalrating: 'DOCTOR.TOTALINCOME.TOTALRATING',
    summary: 'DOCTOR.TOTALINCOME.SUMMARY',
    minutesago: 'DOCTOR.TOTALINCOME.MINUTESAGO',

    paid: 'DOCTOR.TOTALINCOME.PAID'
  },
];