import { DOCUMENT, formatDate } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
const document: any = window.document;
import { ToastrService } from "ngx-toastr";
import { Socket } from "ngx-socket-io";
import { SharedServiceService } from "src/app/common/shared-service.service";
import Swal from "sweetalert2";
import { InterfaceSocketDigiPay } from "./interface/interface-socket-digipay";
import { GlobalConstants } from "src/app/common/global-constants";
import { OneSignalService } from "src/app/common/one-signal.service";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { interval, Subscription } from "rxjs";
import { TimeStartDialogComponent } from "./dialogs/time-start-dialog/time-start-dialog.component";
import { ChangPasswordDialogComponent } from "./dialogs/chang-password-dialog/chang-password-dialog.component";
import { AppointmentsService } from "src/app/doctor/appointments/appointments.service";
import { CallComponent } from "src/app/jitsi/jitsi/dialog/call/call.component";
import { TranslateService } from "@ngx-translate/core";
import { LanguageHeader } from "./interface-language/language-item-header";
import { DatePipe } from "@angular/common";
import { PackageService } from "src/app/admin/package/package.service";
import { io } from "socket.io-client";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { DoctorCalendarService } from "src/app/doctor/doctor-calendar/doctor-calendar.service";
import { DoctorTotalIncomeService } from "src/app/doctor/doctor-total-income/doctor-total-income.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit {
  pathUrl = GlobalConstants.REST_API_TELE;
  urlPath2021 = GlobalConstants.URL_PART_2021;
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  id: string;
  nameDoctor: string;
  roleLogin: string;
  changePass: boolean = false;
  userRole: any;
  notifications: any[] = [];
  countnNotifications: number = 0;
  countnNotifications2: number = 0;
  countnNotificationsChat: number = 0;
  private data_JsonToken: any;
  loading: boolean = true;
  isChecked: boolean = false;
  isCheckedNut: boolean = false;
  homeTrigger: boolean = false;

  isocketDigiPay: InterfaceSocketDigiPay;

  windows = [];

  timeCount: any;
  timerCountDown: any;
  intervalId;
  subscription: Subscription;
  dataLocal: any;
  idWork: any;
  dataGetTimeWork: any;
  private socket_chat: any;
  dataCloseNoti: any;
  isCheckedClose: boolean;

  getTimeWorkNutritionistList: any;
  languageHeader: any;
  datatranslate: any;

  timedOut = false;
  lastPing?: Date = null;
  checkTalking: any;

  shownav: boolean = true;
  userDoc: any;
  dataUserId: any;

  // @ViewChild(DoctorCalendarComponent) viewCalendarDoctorHeader: DoctorCalendarComponent;
  // @Output() viewCalendarDoctor = new EventEmitter<DoctorCalendarComponent>();
  // @Output() viewCalendarDoctor = new EventEmitter();

  constructor(
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService,
    private pharmacyService: PharmacyService,
    private socket_: Socket,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private toastr: ToastrService,
    private os: OneSignalService,
    public dialog: MatDialog,
    public workTimeService: AppointmentsService,
    public doctorCalendarService: DoctorCalendarService,
    private datePipe: DatePipe,
    private packageService: PackageService,
    private idle: Idle,
    private keepalive: Keepalive,
    public doctorService: DoctorTotalIncomeService,
  ) {
    super();
    this.idleLoad();

    // this.socket = io('http://socket.achatsocial.com:2022/',{ query: { transports: ['websocket', 'polling', 'flashsocket'] }});
    this.translate.use(localStorage.getItem("lang"));
    this.dataLocal = localStorage.getItem("currentUser");

    if (this.dataLocal != null) {
      this.data_JsonToken = JSON.parse(this.dataLocal);
      // console.log("this.data_JsonToken : ", this.data_JsonToken);

      this.id = this.data_JsonToken.id;
      this.nameDoctor = this.data_JsonToken.username;
      this.roleLogin = this.data_JsonToken.role;
      this.shownav = true;
      if (this.data_JsonToken.changePassStatus == true) {
        this.changePass = true;
      } else {
        this.changePass = false;
      }
    }
  }

  listLang = [
    {
      text: "English",
      flag: "assets/images/flags/united-states.png",
      lang: "en",
    },
    { text: "Thai", flag: "assets/images/flags/thailand.png", lang: "th" },
  ];

  async ngOnInit() {

    this.languageHeader = LanguageHeader[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
    });
    this.dataCloseNoti = localStorage.getItem("closeNotiChat");
    if (this.dataCloseNoti != "false") {
      this.isCheckedClose = true;
    } else {
      this.isCheckedClose = false;
    }
    var getLocalTime = localStorage.getItem("showDialogWorkTime");
    if (getLocalTime == null) {
      this.isChecked = false;
      // this.timerCountDown.unsubscribe();
    } else {
      if (this.isChecked != false) {
        this.timerCountDown = setInterval(this.getElapsedTime, 1000);
      }
      if (this.isCheckedNut != false) {
        this.timerCountDown = setInterval(this.getElapsedTime, 1000);
      }
    }
    if (this.isChecked != false) {
      this.timerCountDown = setInterval(this.getElapsedTime, 1000);
    }
    if (this.isCheckedNut != false) {
      this.timerCountDown = setInterval(this.getElapsedTime, 1000);
    }

    this.config = this.configService.configData;
    this.userRole = this.authService.currentUserValue.role;
    this.userImg = this.authService.currentUserValue.img;

    if (this.userRole === "Admin") {
      this.homePage = "admin/dashboard/main";
      this.homeTrigger = false;
    } else if (this.userRole === "Pharmacy") {
      this.homePage = "pharmacy/dashboard/" + this.id;
      this.homeTrigger = false;
    } else if (this.userRole === "Doctor") {
      this.homePage = "doctor/menu/" + this.id;
      this.homeTrigger = true;
    } else if (this.userRole === "Dealer") {
      this.homePage = "dealer/dashboard/" + this.id;
      this.homeTrigger = false;
    } else if (this.userRole === "Distributor") {
      this.homePage = "distributor/dashboard/" + this.id;
      this.homeTrigger = false;
    } else if (this.userRole === "TelSupport") {
      this.homePage = "telSupport/main/" + this.id;
      this.homeTrigger = false;
    } else if (this.userRole === "Audit") {
      this.homePage = "audit/dashboard/" + this.id;
      this.homeTrigger = false;
    } else {
      this.homePage = "nutritionist/menu/" + this.id;
      this.homeTrigger = true;
    }

    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.sharedServiceService.currentMessage.subscribe((message) => {
      // console.log("count--- : ", message);
      this.countnNotifications = message;
    });

    this.sharedServiceService.countChat.subscribe((message) => {
      this.countnNotificationsChat = message;
    });

    if (this.roleLogin == "Doctor") {
      this.getTimeWork(this.id);
      this.checkChangPassword();
      this.loadData();
      this.userDoctor();
      this.getMessagesSocket();
      this.checkTimeOutCalendar();
      // onesignal
      // this.os.onLoad();
      // if (localStorage.getItem("onesignal-allow") == "false") {
      //   this.addOnesignalPlayerID(Number(this.id));
      // } else {
      //   this.loadOneSingnal();
      // }
    } else if (this.roleLogin == "Pharmacy") {
      this.checkChangPassword();
      this.loadDataNotiOrder(this.id);
      this.getMessagesOrderSocket();
      this.notiChatPharmacy();
      this.loadCountChat();
    } else if (this.roleLogin == "Nutritionist") {
      this.getTimeWorkNutritionist(this.id);
      this.checkChangPassword();
      this.loadData();
      this.getMessagesSocket();
    } else if (this.roleLogin == "Distributor") {
      this.socket_.on("actionPackageChannel", (data: any) => {
        if (data.Action == "request" && data.DistributorId == this.id && data.SentFrom == "requestFormDealer") {
          if (this.packageService.sentByDtt == false) {
            this.notifyPackageDistributor();
          }
        }
        if (data.Action == "cancel" && data.DistributorId == this.id) {
          this.notifyPackageDistributorCancel();
        }
        if (data.Action == "confirm" && data.DistributorId == this.id) {
          this.notifyPackageDistributorConfirm();
        }
        if (data.Action == "cancel" && data.DealerId == this.id) {
          this.notifyPackageDealerCancel();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        }
        if (data.Action == "confirm" && data.DealerId == this.id) {
          if (this.packageService.sentByDtt == false) {
            this.notifyPackageDealerConfirm();
          }
        }
      });
      this.packageService.sentByDtt = false;
    } else if (this.roleLogin == "Dealer") {
      this.socket_.on("actionPackageChannel", (data: any) => {
        if (data.Action == "cancel" && data.DealerId == this.id) {
          this.notifyPackageDealerCancel();
        }
        if (data.Action == "confirm" && data.DealerId == this.id) {
          this.notifyPackageDealerConfirm();
        }
      });
    } else if (this.roleLogin == "Admin") {
      this.socket_.on("actionPackageChannel", (data: any) => {
        if (data.Action == "request" && data.SentFrom == "fromDistributor") {
          if (this.packageService.sentByDtt == false) {
            this.notifyPackagetoAdminRequest();
          }
        }
        if (data.Action == "cancel" && data.SentFrom == "cancelByAdmin") {
          this.notifyPackagetoAdminCancel();
        }
      });
    }
    this.doctorCalendarService.CheckNewAppointmentAfterPaymentTimeOut_().then();
  }

  userDoctor() {
    var doctorList: any;
    this.doctorService.GetDoctorList_(this.id).then((resDoctorList) => {
      doctorList = resDoctorList;
      this.userDoc = doctorList;
    });
  }

  checkTimeOutCalendar() {
    // this.viewCalendarDoctorHeader.viewCalendarDoctor;
    // console.log(this.viewCalendarDoctorHeader.viewCalendarDoctor);
  }

  notifyPackagetoAdminRequest() {
    this.playAudioOrder();
    this.toastr
      .info(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en" ? "Package Request" : "คำขอแพ็กเกจ"}`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`admin/packagerequest/main`]);
      });
  }

  notifyPackagetoAdminCancel() {
    this.playAudioOrder();
    this.toastr
      .error(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en"
          ? "Cancel Package Request"
          : "ยกเลิกคำขอแพ็กเกจ"
        }`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`admin/packagerequest/main`]);
      });
  }

  notifyPackageDealerCancel() {
    this.playAudioOrder();
    this.toastr
      .error(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en"
          ? "Cancel Package Request"
          : "ยกเลิกคำขอแพ็กเกจ"
        }`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`dealer/package/${this.id}`]);
      });
  }

  notifyPackageDealerConfirm() {
    this.playAudioOrder();
    this.toastr
      .success(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en"
          ? "Confirm Package Request"
          : "ยืนยันคำขอแพ็กเกจ"
        }`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`dealer/package/${this.id}`]);
      });
  }

  notifyPackageDistributor() {
    this.playAudioOrder();
    this.toastr
      .info(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en" ? "Package Request" : "คำขอแพ็กเกจ"}`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`distributor/package/${this.id}`]);
      });
  }

  notifyPackageDistributorCancel() {
    this.playAudioOrder();
    this.toastr
      .info(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en"
          ? "Package Canceled"
          : "คำขอแพ็กเกจถูกยกเลิก"
        }`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`distributor/packagerequest/${this.id}`]);
      });
  }

  notifyPackageDistributorConfirm() {
    this.playAudioOrder();
    this.toastr
      .info(
        `${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
        `${this.datatranslate == "en"
          ? "Package Confirmed"
          : "คำขอแพ็กเกจได้รับการอนุมัติ"
        }`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => {
        this.router.navigate([`distributor/packagerequest/${this.id}`]);
      });
  }

  loadOneSingnal() {
    var OneSignal = window["OneSignal"] || [];
    OneSignal.push(function () {
      OneSignal.on("notificationDisplay", function (event) {
        console.warn("OneSignal notification displayed :: ", event);
      });
    });
  }

  async addOnesignalPlayerID(idDoctor: number) {
    this.os.onLoadPlayerID().then((resId) => {
      if (resId != null) {
        var dataOnesignal = {
          DR_Id: idDoctor,
          Onesignal_PayerId: resId,
        };
        this.authService
          .InsertPlayerIDLogin_(dataOnesignal)
          .then((resInsertPlayerIDLogin) => {
            localStorage.setItem("onesignal-allow", "true");
          });
      }
    });
  }
  public getMessagesSocket = () => {
    this.socket_.on("connect", (data: any) => {
      console.log("socket connected");
    });

    var dataSocset =
    {
      nameDoctor: this.nameDoctor,
      drId: this.data_JsonToken.id,
    }
    this.socket_.emit("Doctor-Online", dataSocset);

    this.socket_.on("datasocket_tele", (data: any) => {
      // console.log("datasocket_tele", data);
      this.loadData();
      if (data.Recive_ID == this.id) {
        this.showSuccess(
          data.User_ID,
          data.Recive_ID,
          data.AppointmentTime,
          data.Username
        );
      }
    });
    this.socket_.on("send_ActionChannel", (data: any) => {
      if (data.SenderId == this.id.toString()) {
        // console.log("send_ActionChannel ------>  ", data);
        if (data.Action == "convPaymentFail") {
          this.playAudioOrder();
          this.doctorService.GetUserByID_(data.ReceiverId).then((resList) => {
            this.dataUserId = resList;

            this.alertNitiCancelAppointmentsPayment(
              "",
              data.SenderId,
              data.ReceiverId,
              this.dataUserId.user_Name,
              this.dataUserId.user_Pic,
              data.Timestamp
            );
          });
          // this.sharedServiceService.updateDoctorAcceptAppointment(true);
        } else if (data.Action == "timeOutPayment") {
          this.playAudioOrder();
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.datatranslate == "th" ? "คุณไม่ได้ตอบรับนัดในช่วงเวลา 5 นาที การขอนัดหมายจึงถูกยกเลิก" : "You have not accepted an appointment within the 5 minute period, so the appointment request has been cancelled.",
            showConfirmButton: true,
            allowOutsideClick: false,
            width: '500px'
          });
          this.sharedServiceService.updateDoctorAcceptAppointment(true);
        } else {
          this.sharedServiceService.updateDoctorAcceptAppointment(true);
        }

      }
    });

    this.socket_.on("newAppointmentDate_tele", (data: any) => {
      if (data.DoctorId == this.id.toString()) {
        // console.log("newAppointmentDate_tele ------>  ", data);
        this.sharedServiceService.updateDoctorAcceptAppointment(true);
      }
    });

    this.socket_.on("startWorkingSocketTele_tele", (data: any) => {
      if (data.DoctorId == this.id) {
        this.getTimeWork(this.id);
      }
    });

    this.socket_.on("overtime_tele", (data: any) => {
      // console.log("header data overtime ", data);
      // if (data.Action == "pending") {
      //   if (data.Id == this.id) {
      //     // this.disableCountdown = true;
      //     this.alertWaitingForPayment();
      //   }
      // }

      // if (data.Action == "cancle") {
      //   if (data.Id == this.id) {
      //     // this.disableCountdown = false;
      //     Swal.close();
      //   }
      // }

      // if (data.Action == "success") {
      //   if (data.Id == this.id) {
      //     // this.disableCountdown = false;
      //     // setTimeout(() => {
      //     //   Swal.close();
      //     //   this.loadUserChatAppointments();
      //     // }, 500);
      //   }
      // }
    });

    this.socket_.on("datasocket_digiPay", (dataDigiPay: any) => {
      // console.log("dataDigiPay---->", dataDigiPay);
      var idSocket: any;
      this.isocketDigiPay = dataDigiPay;
      if (
        this.roleLogin == "Doctor"
          ? this.isocketDigiPay.DoctorID == this.id
          : this.isocketDigiPay.NutritionistID == this.id
      ) {
        if (
          localStorage.getItem("referenceDigipay") == null ||
          this.isocketDigiPay.Reference !=
          localStorage.getItem("referenceDigipay")
        ) {
          // var dateNow = moment(new Date().getTime()).format('DDMMYYYYHHmm');
          // var dateNow2 = moment(new Date(this.isocketDigiPay.DateTimeAppointment)).format('DDMMYYYYHHmm');

          if (this.isocketDigiPay.TypeConversation == "video" && new Date(this.isocketDigiPay.DateTimeAppointment) <= new Date()) {
            this.playAudioOrder();
            this.alertVideoCall(this.isocketDigiPay);
            localStorage.setItem(
              "conferenceLink",
              this.isocketDigiPay.UrlVideoCall
            );
            localStorage.setItem("stutusConfer", "start");
            localStorage.setItem(
              "idUserConference",
              this.isocketDigiPay.UserID
            );
            localStorage.setItem(
              "referenceDigipay",
              this.isocketDigiPay.Reference
            );
          } else if (this.isocketDigiPay.TypeConversation == "video" && new Date(this.isocketDigiPay.DateTimeAppointment) > new Date()) {
            this.playAudioOrder();
            this.alertVideoAppoment(this.isocketDigiPay);
            localStorage.setItem(
              "conferenceLink",
              this.isocketDigiPay.UrlVideoCall
            );
            localStorage.setItem("stutusConfer", "start");
            localStorage.setItem(
              "idUserConference",
              this.isocketDigiPay.UserID
            );
            localStorage.setItem(
              "referenceDigipay",
              this.isocketDigiPay.Reference
            );
          } else {
            this.playAudioOrder();
            this.alertChat(this.isocketDigiPay);
            localStorage.setItem("conferenceLink", "chat");
            localStorage.setItem("stutusConfer", "start");
            localStorage.setItem(
              "idUserConference",
              this.isocketDigiPay.UserID
            );
            localStorage.setItem(
              "referenceDigipay",
              this.isocketDigiPay.Reference
            );
          }
        } else {
          console.log("Reference เหมือนกัน");
        }
      }
    });

    this.socket_.on("datasocket_appointmentNotificationDoctor", (data: any) => {
      // console.log("datasocket_appointmentNotificationDoctor", data);
      if (data.Recive_ID == this.id) {
        this.alertNitiDoctor(
          data.AppointmentTime,
          data.Min,
          data.Username,
          data.Userpic
        );
      }
    });
    this.socket_.on("datasocket_userCancelConverseDoctor", (data: any) => {
      // console.log("datasocket_userCancelConverseDoctor", data);
      if (data.DoctorID == this.id.toString()) {
        if (data.StatusCancel == "cancelTalkingSuccess") {
          this.playAudioOrder();
          this.cancelTalking(data);
        } else if (data.StatusCancel == "cancelBooking") {
          this.playAudioOrder();
          this.cancelBooking(data);
          this.sharedServiceService.alertDataSocketUserCancelConverse(data);
        } else if (data.StatusCancel == "cancelAppointments") {
          this.playAudioOrder();
          this.authService
            .GetConvosationByIdCon_(Number(data.ConvoID))
            .then((res) => {
              // console.log(res);
              if (res.data.convO_DoctorAccept == true) {
                this.alertNitiCancelAppointmentsPayment(
                  data.ConvoID,
                  data.DoctorID,
                  data.UserID,
                  data.Username,
                  data.Userpic,
                  data.Timestamp
                );
              } else {
                this.alertNitiCancelAppointments(
                  data.ConvoID,
                  data.DoctorID,
                  data.UserID,
                  data.Username,
                  data.Userpic,
                  data.Timestamp
                );
              }
              this.sharedServiceService.alertDataSocketUserCancelConverse(data);
            });
        } else {
          // this.sharedServiceService.alertDataSocketUserCancelConverse("");
        }
        this.loadData()
      }

    });

    this.socket_.on("confirmAppointmentDate_tele", (data: any) => {
      // console.log("รอตอบรับ------->", data);
      // if (data.Action == "confirm") {
      //   localStorage.removeItem("changTimes");
      //   this.toastr
      //     .info(
      //       `${this.datatranslate == "en" ? "Time" : "เวลา"
      //       } ${this.datePipe.transform(Date.now(), "d/M/y, h:mm:ss a")}`,
      //       `${this.datatranslate == "en"
      //         ? "Package Confirmed"
      //         : "คำขอเลื่อนเวลานัดหมายล่วงหน้าได้รับการอนุมัติ"
      //       }`,
      //       {
      //         enableHtml: true,
      //         newestOnTop: true,
      //         timeOut: 10000,
      //         extendedTimeOut: 3000,
      //         progressBar: true,
      //       }
      //     )
      //     .onTap.subscribe(() => {
      //       this.router.navigate([`doctor/appointments-calendar/${this.id}`]);
      //     });
      // }
    });

    this.socket_.on("newAppointmentDateAfterPayment_tele", (data: any) => {
      // console.log("socket : ", data);
      this.sharedServiceService.updateAppointmentAftePayment(data);

      var textTitle = "แจ้งเตือนการเลื่อนนัด"
      switch (data.Action) {
        case "confirm": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ยืนยันเวลาที่ คุณ นัดแล้ว" : "The user has confirmed your appointed time."
          break;
        }
        case "currentTime": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ยืนยันเวลาเดิม" : "User confirms original time"
          break;
        }
        case "refundTransfer": {
          textTitle = this.datatranslate == "th" ? "คนไข้ปฏิเสธการเลื่อนนัดหมายครั้งนี้" : "The patient refused to postpone this appointment."
          break;
        }
        case "refundCash": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ขอคืนเป็น เงิน" : "The user requests a refund in money."
          break;
        }
        case "refundPoint": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ขอคืนเป็น Point" : "The user requests a refund in Points."
          break;
        }
        case "refundTele": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ขอคืนเป็น Tele Free" : "The user requests a refund to Tele Free."
          break;
        }
        case "changeDate": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ต้องการเปลี่ยนนัดหมายเวลาใหม่" : "The user wants to change an appointment to a new time."

          break;
        }
        case "cancelRequest": {
          textTitle = this.datatranslate == "th" ? "ผู้ใช้ยกเลิกนัดหมายเวลาใหม่" : "User cancels appointment to reschedule"
          break;
        }
        default: {
          //statements; 
          break;
        }
      }
      if (textTitle != 'แจ้งเตือนการเลื่อนนัด') {
        this.countNotifyChangNewDateByUser();
        this.sharedServiceService.countDownAppointmentSidebar(true);
        this.playAudioOrder();
        this.toastr
          .info(
            textTitle,
            this.datatranslate == "th" ? 'แจ้งเตือน' : "Notifications",

            {
              enableHtml: true,
              newestOnTop: true,
              timeOut: 10000,
              extendedTimeOut: 3000,
              progressBar: true,
            }
          )
          .onTap.subscribe(() => {
            this.router.navigate([`doctor/appointments-calendar/${data.DoctorId}`]);
          });
        Swal.fire({
          icon: 'warning',
          title: this.datatranslate == "th" ? 'แจ้งเตือน' : "Notifications",
          text: textTitle,
          allowOutsideClick: false,
        }).then(async (result) => {
          this.router.navigate([`doctor/appointments-calendar/${data.DoctorId}`]);
        })
      }
    });
  };

  countNotifyChangNewDateByUser() {
    this.doctorCalendarService.GetDoctorUpdateAppointmentAfterPaymentByDoctor_(Number(this.id)).then(resppointmentAfter => {
      var countNotifyChangNewDate = 0;
      if (resppointmentAfter.data.length > 0) {
        countNotifyChangNewDate = resppointmentAfter.data.filter(x => x.dataRequestNewDate.fromToAction == "user").length;
        // console.log("countNotifyChangNewDate ", countNotifyChangNewDate);
        // console.log(" : ", resppointmentAfter.data);
        this.sharedServiceService.countNotiAlertChangDateSidebar(countNotifyChangNewDate);

        for (let i = 0; i < resppointmentAfter.data.length; i++) {
          this.notifications.push({
            idNoti: resppointmentAfter.data[i].dataConvo.convoId,
            userImg: resppointmentAfter.data[i].dataConvo.dataUser.img,
            userName: resppointmentAfter.data[i].dataConvo.dataUser.name,
            time: resppointmentAfter.data[i].dataRequestNewDate.dateAppointment,
            message:
              this.datatranslate == "en"
                ? "Request to postpone appointment time."
                : "คำขอเลื่อนเวลานัดหมาย",
          });
          if (i == resppointmentAfter.data.length - 1) {
            this.countnNotifications2 = resppointmentAfter.data.length;
          }
        }

      } else {
        this.countnNotifications2 = 0;
        this.sharedServiceService.countNotiAlertChangDateSidebar(0);
      }
    });
  }

  cancelBooking(data: any) {
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (data.Userpic != "") {
      ImgDoc = this.urlPath2021 + data.Userpic;
    }

    var time = moment(data.Timestamp).format('DD/MM/YYYY HH:mm');
    var nameFull = this.userDoc.drFname + " " + this.userDoc.drLname;
    Swal.fire({
      html: `<img src='${ImgDoc}'
      alt='' style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h2 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en"
          ? "Cancelling a booking"
          : "ยกเลิกการจอง"
        }</b></h2>
      <h4 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? `Patient ${data.Username} <br> Have an appointment with you ${nameFull} <br> Time ${time} <br>the appointment has been cancelled.`
          : `คนไข้ ${data.Username} <br> มีนัดกับคุณ ${nameFull}<br> เวลา ${time} <br>ได้มีการยกเลิกการจอง`
        }</h4>`,
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // window.location.reload();
      }
    });
  }

  alertNitiCancelAppointments(
    convoID: string,
    doctorID: string,
    userID: string,
    userName: string,
    userPic: string,
    timeStamp: any
  ) {
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (userPic != "") {
      ImgDoc = this.urlPath2021 + userPic;
    }

    var time = moment(timeStamp).format('DD/MM/YYYY HH:mm');
    var nameFull = this.userDoc.drFname + " " + this.userDoc.drLname;
    Swal.fire({
      html: `<img src='${ImgDoc}'
      alt='' style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h2 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en"
          ? "Cancel appointment"
          : "ยกเลิกการนัดหมาย"
        }</b></h2>
      <h4 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? `Patient ${userName} <br> Have an appointment with you ${nameFull} <br> Time ${time} <br>the appointment has been cancelled.`
          : `คนไข้ ${userName} <br> มีนัดกับคุณ ${nameFull}<br> เวลา ${time} <br>ได้มีการยกเลิกการนัดหมาย`
        }</h4>`,
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  alertNitiCancelAppointmentsPayment(
    convoID: string,
    doctorID: string,
    userID: string,
    userName: string,
    userPic: string,
    timeStamp: any
  ) {
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (userPic != "") {
      ImgDoc = this.urlPath2021 + userPic;
    }

    var time = moment(timeStamp).format('DD/MM/YYYY HH:mm');
    var nameFull = this.userDoc.drFname + " " + this.userDoc.drLname;
    Swal.fire({
      html: `<img src='${ImgDoc}'
      alt='' style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h2 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en"
          ? "Cancel payment"
          : "ยกเลิกการจ่ายเงิน"
        }</b></h2>
      <h4 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? `Patient ${userName} <br> Have an appointment with you ${nameFull} <br> Time ${time} <br>the appointment has been cancelled.`
          : `คนไข้ ${userName} <br> มีนัดกับคุณ ${nameFull}<br> เวลา ${time} <br>ได้มีการยกเลิกการจ่ายเงิน`
        }</h4>`,
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  cancelTalking(data: any) {

    if (data.DoctorID == this.id) {
      var idUserConF_ = localStorage.getItem("idUserConference");
      if (this.roleLogin == "Doctor" && this.id == data.DoctorID) {
        localStorage.removeItem("changTimes");
        this.router.navigate([
          `/doctor/doctor-chats/video/${this.id}_${idUserConF_}/end`,
        ]);
        localStorage.setItem("stutusConfer", "end");
      } else if (
        this.roleLogin == "Nutritionist" &&
        this.id == data.NutritionistID
      ) {
        this.router.navigate([
          `/nutritionist/nutritionist-chats/video/${this.id}_${idUserConF_}/end`,
        ]);
        localStorage.setItem("stutusConfer", "end");
      }
      if (this.id == data.DoctorID) {
        localStorage.removeItem("conferenceLink");
        localStorage.removeItem("idUserConference");

        this.alertNitiCancelConverDoctor(
          data.ConvoID,
          this.roleLogin == "Doctor" ? data.DoctorID : data.NutritionistID,
          data.UserID,
          data.Username,
          data.Userpic
        );

        this.loadData();
      } else if (this.id == data.NutritionistID) {
        localStorage.removeItem("conferenceLink");
        localStorage.removeItem("idUserConference");
        this.alertNitiCancelConverDoctor(
          data.ConvoID,
          this.roleLogin == "Doctor" ? data.DoctorID : data.NutritionistID,
          data.UserID,
          data.Username,
          data.Userpic
        );
      } else {
        // console.log("");
      }
    }
  }

  getMessagesOrderSocket() {
    this.socket_.on("connect", (data: any) => {
      console.log("socket connected order");
    });

    this.socket_.emit("Pharmacy-Online", this.nameDoctor);

    this.socket_.on("datamessage_orderMedicine", (data: any) => {
      if (data.PhamacyID == this.id) {
        if (data.OrderID != localStorage.getItem("NewOrder")) {
          localStorage.setItem("NewOrder", data.OrderID);
          this.loadDataNotiOrder(this.id);
          this.showAlertOrder(
            data.DateOrder,
            data.OrderID,
            data.PhamacyID,
            data.TotalPrice,
            data.TypePayment,
            data.UserName,
            data.UserPic
          );
        }
      }
    });

    this.socket_.on("call_pharmacy", (dataCall: any) => {
      // console.log("dataCall ", dataCall);
      if (
        dataCall.PharmacyID == this.id &&
        dataCall.PharmacyConvoIsTalking == "call"
      ) {
        var dataCallObj = {
          userID: dataCall.UserID,
          userName: dataCall.Username,
          userPic: dataCall.Userpic,
          pharmacyConvoLinkMeeting: dataCall.PharmacyConvoLinkMeeting,
          pharmacyConvoID: dataCall.PharmacyConvoID,
        };
        this.openDialogCallPharcy(dataCallObj);
      }
    });
  }

  notiChatPharmacy() {
    // this.loadUserChat();
    this.socket_chat = io(`${GlobalConstants.REST_API_CHAT}`);

    this.socket_chat.on("connect", () => {
      // console.log("socket_ connect --");
      this.socket_chat.emit("Achat-Online", { user_id: "" + this.id });
    });

    this.socket_chat.on("disconnect", (data) => {
      //   console.log("onDisconnect");
    });

    this.socket_chat.on("reconnect", (data) => {
      //   console.log("onReconnect");
      this.socket_chat.emit("Achat-Online", { user_id: "" + this.id });
    });

    this.socket_chat.on("Notify", (mymsgNoti: any) => {
      var msgchat: any;
      if (this.dataCloseNoti != "false") {
        if (mymsgNoti.msg != "") {
          msgchat = mymsgNoti.msg;
          Swal.fire({
            toast: true,
            iconHtml: `<img src="${mymsgNoti.profile_img_path}" class="rounded-circle" width="32" height="32" alt="User"
            style="object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> `,
            // icon:'error',
            // imageUrl: `${mymsg.profile_img_path}`,
            //instead of imageSize use imageWidth and imageHeight
            imageWidth: 35,
            html: `${mymsgNoti.msg}`,
            title: `${mymsgNoti.sender_name}`,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        } else {
          if (mymsgNoti.media_type == "Sticker") {
            msgchat = "Sticker sent";
          } else {
            msgchat = "Photo sent";
          }
          Swal.fire({
            toast: true,
            iconHtml: `<img src="${mymsgNoti.profile_img_path}" class="rounded-circle" width="32" height="32" alt="User"
            style="object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> `,
            // icon:'error',
            // imageUrl: `${mymsg.profile_img_path}`,
            //instead of imageSize use imageWidth and imageHeight
            imageWidth: 35,
            html: `<img src="${mymsgNoti.media_path}" class="img-circle doc-card-image"
            style="width: 32px;height: 32px;object-fit: cover;" onerror="this.src='assets/images/imgdoctor/nouser.svg'"> ${msgchat}`,
            title: `${mymsgNoti.sender_name}`,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
        this.playAudioChat();
      }
      this.loadCountChat();
    });
  }

  CloseNotiChat(status: any) {
    localStorage.setItem("closeNotiChat", status.checked);
    this.dataCloseNoti = localStorage.getItem("closeNotiChat");
  }

  loadCountChat() {
    var dataGetLastHistory: any;
    var countMSG = 0;
    this.pharmacyService
      .GetLastHistoryChatlist(this.id)
      .then((resGetLastHistoryChatlist) => {
        // console.log("resGetLastHistoryChatlist ", resGetLastHistoryChatlist);

        dataGetLastHistory = resGetLastHistoryChatlist;
        for (let i = 0; i < dataGetLastHistory.length; i++) {
          if (dataGetLastHistory[i].amount_new_msg > 0) {
            countMSG++;
          }
          if (i == dataGetLastHistory.length - 1) {
            this.countnNotificationsChat = countMSG;
            this.sharedServiceService.updateCountChat(countMSG);
          }
        }
      });
  }

  openDialogCallPharcy(dataCall: any): void {
    const dialogRef = this.dialog.open(CallComponent, {
      disableClose: true,
      width: "400px",
      data: dataCall,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        var dataUpdateCall = {
          pharConvo_Id: dataCall.pharmacyConvoID,
          pharConvo_IsTalking: true,
          HangUp: "pharmacy",
        };

        // this.pharmacyService.InsertAndUpdatePhamarcyConversation_(dataUpdateCall).then(resInsertAndUpdatePhamarcyConversation => {
        // localStorage.setItem("dataCall", JSON.stringify(dataCall));
        // const strCopy = dataCall.pharmacyConvoLinkMeeting.split("https://conference.achatsocial.com/");
        // console.log("-------->",dataCall.pharmacyConvoLinkMeeting);
        const strCopy = dataCall.pharmacyConvoLinkMeeting.split(
          "https://meet.medilifewellness.com/"
        );
        const strCopy2 = strCopy[1].split("?jwt=");
        // var roomId = `PHAR${this.idDoctor}_${this.dataUserConvosationAppointments[0].dataConver.userId}`;
        // window.open(`https://releep.achatsocial.com:4000/#/releep/call/${dataCall.pharmacyConvoID}/${strCopy2[0]}/${strCopy2[1]}`, '_blank', 'location=yes,height=500,width=900,scrollbars=yes,status=yes');
        // window.open(`http://localhost:4000/#/releep/call/pharmacy/${dataCall.pharmacyConvoID}/${strCopy2[0]}/${strCopy2[1]}`, '_blank');
        // window.open(
        //   `http://localhost:4200/#/rux/call/pharmacy/${dataCall.pharmacyConvoID}/${strCopy2[0]}/${strCopy2[1]}`,
        //   "_blank"
        // );
        // `https://rl-dash.achatsocial.com/#/rux/callDoctor/doctor/
        window.open(
          `https://rl-dash.achatsocial.com/#/rux/call/pharmacy/PHAR${dataCall.pharmacyConvoID}/${strCopy2[0]}/${strCopy2[1]}`,
          "_blank"
        );
      }
    });
  }

  showAlertOrder(
    dateOrder: string,
    orderID: string,
    phamacyID: string,
    totalPrice: string,
    typePayment: string,
    userName: string,
    userPic: string
  ) {
    var OrderN: any;
    var payments: any;
    var baht: any;
    var Orderpaymentstatus: any;
    if (this.datatranslate == "en") {
      OrderN = "Order number";
      payments = "Order number";
      baht = "baht";
      Orderpaymentstatus = "Order payment status";
    } else {
      OrderN = "หมายเลขสั่งซื้อ";
      payments = "สถานะการชำระเงิน";
      baht = "บาท";
      Orderpaymentstatus = "สถานะการชำระเงินสั่งซื้อสินค้า";
    }
    this.playAudioOrder();
    this.toastr
      .success(
        `${OrderN} ${orderID} <br> ${payments} ${typePayment} ${totalPrice} ${baht} <br> <i class="far fa-clock"></i> ${dateOrder}`,
        `${Orderpaymentstatus}`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 15000,
          extendedTimeOut: 5000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() => this.readNoti(orderID, "Pharmacy"));
  }

  showSuccess(
    idUser: string,
    idConver: string,
    AppointmentTime: string,
    Username: string
  ) {
    var Request: any;
    if (this.datatranslate == "en") {
      if (this.userRole == "Doctor") {
        Request = "Conversation requested";
      } else {
        Request = "Order number";
      }
    } else {
      if (this.userRole == "Doctor") {
        Request = "คำขอสนทนา";
      } else {
        Request = "หมายเลขสั่งซื้อ";
      }
    }
    this.playAudioOrder();
    this.toastr
      .info(
        `${Request} <br> ${this.datatranslate == "en" ? "Time" : "เวลา"
        } ${this.datePipe.transform(AppointmentTime, "d/M/y, h:mm:ss a")}`,
        `${Username}`,
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      )
      .onTap.subscribe(() =>
        this.readNoti(
          idConver,
          this.roleLogin == "Doctor" ? "Doctor" : "Nutritionist"
        )
      );
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
    this.sharedServiceService.updateCompcompHeaderValLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    this.shownav = hasClass;
    // console.log(this.shownav);

    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  readNoti(id: string, role: string) {
    if (role == "Doctor") {
      this.authService
        .Readconversation_(id, "read")
        .then((resReadconversation) => {
          this.router.navigate([`doctor/appointments/${this.id}`]);
          this.loadData();
        });
    } else if (role == "Nutritionist") {
      this.authService
        .Readconversation_(id, "read")
        .then((resReadconversation) => {
          this.router.navigate([`nutritionist/appointments/${this.id}`]);
          this.loadData();
        });
    } else {
      this.authService.ReadOrder_(id, "read").then((resReadOrder) => {
        this.router.navigate([`pharmacy/order-drug-list/${this.id}`]);
        this.loadDataNotiOrder(this.id);
      });
    }
  }

  onClickRead(idConver: string, index: number, msg?: string) {
    var checkMsg = this.datatranslate == "en"
      ? "Request to postpone appointment time."
      : "คำขอเลื่อนเวลานัดหมาย"
    if (msg != checkMsg) {
      if (this.roleLogin == "Doctor") {
        this.authService
          .Readconversation_(idConver, "read")
          .then((resReadconversation) => {
            this.notifications.splice(index, 1);
            this.sharedServiceService.changeMessage(this.notifications.length);
            this.router.navigate([`doctor/appointments/${this.id}`]);
          });
      } else if (this.roleLogin == "Nutritionist") {
        this.authService
          .Readconversation_(idConver, "read")
          .then((resReadconversation) => {
            this.notifications.splice(index, 1);
            this.sharedServiceService.changeMessage(this.notifications.length);
            this.router.navigate([`nutritionist/appointments/${this.id}`]);
          });
      } else {
        this.authService.ReadOrder_(idConver, "read").then((resReadOrder) => {
          this.notifications.splice(index, 1);
          this.sharedServiceService.changeMessage(this.notifications.length);
          this.router.navigate([`pharmacy/order-drug-list/${this.id}`]);
        });
      }
    } else {
      this.router.navigate([`doctor/appointments-calendar/${this.id}`]);
    }

  }

  private loadData() {
    this.loading = true;
    this.notifications = [];
    var dataGetConvosation: any;
    if (this.roleLogin == "Nutritionist") {
      this.authService
        .GetConvosation_(Number(this.id))
        .then((resGetConvosation) => {
          dataGetConvosation = resGetConvosation;

          if (dataGetConvosation.length > 0) {
            for (let i = 0; i < dataGetConvosation.length; i++) {
              if (
                dataGetConvosation[i].convoDoctorAccept == null &&
                dataGetConvosation[i].convoRead == "unread"
              ) {
                this.notifications.push({
                  idNoti: dataGetConvosation[i].convoId,
                  userImg: dataGetConvosation[i].userPic,
                  userName: dataGetConvosation[i].userName,
                  time: dataGetConvosation[i].convoDateAppointment,
                  message:
                    this.datatranslate == "en"
                      ? "request a consultation"
                      : "ขอนัดปรึกษา",
                });
                if (i == dataGetConvosation.length - 1) {
                  this.countnNotifications = this.notifications.length;

                  this.sharedServiceService.changeMessage(
                    this.notifications.length
                  );
                  this.loading = false;
                }
              } else {
                if (i == dataGetConvosation.length - 1) {
                  this.loading = false;
                }
              }
            }
          } else {
            this.loading = false;
          }
        });
    } else if (this.roleLogin == "Doctor") {
      this.authService
        .GetConvosationDoc_(Number(this.id))
        .then((resGetConvosation) => {
          dataGetConvosation = resGetConvosation;
          this.checkTalking = dataGetConvosation.sort((a, b) => a.convoId - b.convoId);
          // console.log("resGetConvosation", this.checkTalking);

          if (dataGetConvosation.length > 0) {
            var datanotiUnread = dataGetConvosation.filter(x => x.convoDoctorAccept == null && x.convoRead == "unread");
            if (datanotiUnread.length > 0) {
              for (let i = 0; i < datanotiUnread.length; i++) {
                this.notifications.push({
                  idNoti: datanotiUnread[i].convoId,
                  userImg: datanotiUnread[i].userPic,
                  userName: datanotiUnread[i].userName,
                  time: datanotiUnread[i].convoDateAppointment,
                  message:
                    this.datatranslate == "en"
                      ? "request a consultation"
                      : "ขอนัดปรึกษา",
                });

                if (i == datanotiUnread.length - 1) {
                  this.countnNotifications = this.notifications.length;
                  this.sharedServiceService.changeMessage(
                    this.notifications.length
                  );
                  this.loading = false;
                }
              }
            } else {
              this.sharedServiceService.changeMessage(0);
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        });
      this.countNotifyChangNewDateByUser();
    }
  }

  loadDataNotiOrder(idPharmacy: string) {
    this.loading = true;
    this.notifications = [];
    var dataOrderUnReadPharmacy: any;
    this.pharmacyService
      .GetOrderUnReadPharmacy_(idPharmacy)
      .then((resOrderUnReadPharmacy) => {
        dataOrderUnReadPharmacy = resOrderUnReadPharmacy;
        // console.log(dataOrderUnReadPharmacy);

        if (dataOrderUnReadPharmacy.length > 0) {
          for (let i = 0; i < dataOrderUnReadPharmacy.length; i++) {
            if (dataOrderUnReadPharmacy[i].receipt.recpT_StatusPay == true) {
              this.notifications.push({
                idNoti: dataOrderUnReadPharmacy[i].idorder,
                userImg: dataOrderUnReadPharmacy[i].dataUser.user_Pic,
                userName: dataOrderUnReadPharmacy[i].dataUser.user_Name,
                time: dataOrderUnReadPharmacy[i].order.ordeR_Date,
                message: `${this.datatranslate == "en"
                  ? "Order number"
                  : "หมายเลขสั่งซื้อ"
                  } ${dataOrderUnReadPharmacy[i].idorder}, ${this.datatranslate == "en"
                    ? "Payment status"
                    : "สถานะการชำระเงิน"
                  } ${dataOrderUnReadPharmacy[i].receipt.recpT_TypePayment}`,
                totalPrice: `${this.datatranslate == "en" ? "Total price" : "รวมราคา"
                  } ${dataOrderUnReadPharmacy[i].receipt.recpT_TotalPrice} ${this.datatranslate == "en" ? "baht" : "บาท"
                  }`,
              });
              if (i == dataOrderUnReadPharmacy.length - 1) {
                this.countnNotifications = this.notifications.length;
                this.sharedServiceService.changeMessage(
                  this.notifications.length
                );
                this.loading = false;
              }
            } else {
              this.loading = false;
            }
          }
        } else {
          this.loading = false;
        }
      });
  }

  alertNitiDoctor(
    appointmentTime: string,
    min: string,
    userName: string,
    userPic: string
  ) {
    var minn: any = "";
    if (min != "" && min != null && min != undefined) {
      minn = min.split(".");
    }

    //test

    // var date_ = moment(appointmentTime).format(); //ss
    // var dateA = moment(date_).format("YYYY-MM-DD, h:mm:ss a");
    // var today = new Date();
    // var diffMs = (new Date(dateA).getTime() - today.getTime());
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // console.log( diffDays, " day ", diffHrs, " h ", diffMins, " m ");
    Swal.fire({
      html: `<img src='${this.urlPath2021}${userPic}'
      onerror='this.src='assets/images/imgdoctor/nouser.svg'' alt='' style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h2 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en" ? "Appointment" : "การนัดหมาย"
        }</b></h2>
      <h4 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? "Do you have a consultation appointment"
          : "คุณมีนัดให้คำปรึกษา"
        } <br>${this.datatranslate == "en" ? "you" : "คุณ"} ${userName} <br>${this.datatranslate == "en" ? "at the time" : "ณ เวลา"
        } ${appointmentTime} <br>${this.datatranslate == "en" ? "about" : "อีกประมาณ"
        } ${minn[0]} ${this.datatranslate == "en" ? "minute" : "นาที"}</h4>`,
      confirmButtonText: this.datatranslate == "en" ? "confirm" : "ยืนยัน",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // window.location.reload();
        // this.router.navigate([`/doctor/appointments-calendar/${this.id}`], { replaceUrl: true }).then(() => { window.location.reload(); });
        this.router.navigate([`/doctor/appointments-calendar/${this.id}`]);
      }
    });
  }

  alertNitiCancelConverDoctor(
    convoID: string,
    doctorID: string,
    userID: string,
    userName: string,
    userPic: string
  ) {
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (userPic != "") {
      ImgDoc = this.urlPath2021 + userPic;
    }
    Swal.fire({
      html: `<img src='${ImgDoc}'
      alt='' style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h2 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en"
          ? "Conversation has been ended."
          : "จบการสนทนา"
        }</b></h2>
      <h4 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en" ? "" : "คุณ"
        } ${userName}</h4>`,
      // <br>${this.datatranslate == "en"
      //   ? "Cancel the conversation"
      //   : "ยกเลิกการสนทนา"
      // }
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed && this.roleLogin == "Doctor") {
        this.router.navigate([`/doctor/dashboard/${this.id}`]);
      } else {
        this.router.navigate([`/nutritionist/dashboard/${this.id}`]);
      }
    });
  }

  alertVideoAppoment(dataDigipay: any) {
    this.loadData();
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (dataDigipay.UserImg != "") {
      ImgDoc = this.urlPath2021 + dataDigipay.UserImg;
    }
    Swal.fire({
      position: "center",
      title:
        this.datatranslate == "en"
          ? "Appointment payment status"
          : "สถานะชำระการนัดหมายล่วงหน้า",
      html: `<img src='${ImgDoc}'style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h4 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en" ? "Payment status" : "สถานะการชำระ เลขที่"
        }<br>${dataDigipay.Reference}<br>${this.datatranslate == "en" ? "succeed" : "สำเร็จเรียบร้อย"
        }</h4>
      <h5 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? "Payer's name"
          : "ชื่อผู้ชำระเงิน : "
        }${dataDigipay.Username}</h5>`,
      confirmButtonColor: "#ff7f50",
      confirmButtonText:
        this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true && this.roleLogin == "Doctor") {
        this.router.navigate([`/doctor/appointments-calendar/${this.id}`], { replaceUrl: true }).then(() => { window.location.reload(); });
      }
    })
  }
  alertVideoCall(dataDigipay: any) {
    this.loadData();
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (dataDigipay.UserImg != "") {
      ImgDoc = this.urlPath2021 + dataDigipay.UserImg;
    }
    Swal.fire({
      position: "center",
      title:
        this.datatranslate == "en"
          ? "Conversation payment status"
          : "สถานะชำระการสนทนา",
      html: `<img src='${ImgDoc}'style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h4 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en" ? "Payment status" : "สถานะการชำระ เลขที่"
        }<br>${dataDigipay.Reference}<br>${this.datatranslate == "en" ? "succeed" : "สำเร็จเรียบร้อย"
        }</h4>
      <h5 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en"
          ? "Please press start chat"
          : "กรุณากดเริ่มการสนทนา"
        }<br> ${this.datatranslate == "en"
          ? "to join the conversation with"
          : "เพื่อเข้าร่วมการสนทนากับ"
        }<br>${dataDigipay.Username}</h5>`,
      confirmButtonColor: "#ff7f50",
      confirmButtonText:
        this.datatranslate == "en" ? "Start a conversation" : "เริ่มการสนทนา",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true && this.roleLogin == "Doctor" && this.checkTalking[this.checkTalking.length - 1].convoIsTalking == true) {
        // window.open(dataDigipay.UrlVideoCall, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        // this.resetWindowsAndCloseTabs();
        this.router.navigate([
          `/doctor/doctor-chats/video/${this.id}_${dataDigipay.UserID}/start`,
        ]);
        // this.windows.push(window.open(dataDigipay.UrlVideoCall, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes'));
      } else if (result.isConfirmed == true && this.roleLogin == "Nutritionist") {
        this.router.navigate([
          `/nutritionist/nutritionist-chats/video/${this.id}_${dataDigipay.UserID}/start`,
        ]);
      } else if (result.isConfirmed == false && this.roleLogin == "Doctor") {
        this.router.navigate([
          `/doctor/doctor-chats/video/${this.id}_${dataDigipay.UserID}/start`,
        ]);
      }
      else if (result.isConfirmed == true && this.roleLogin == "Doctor" && this.checkTalking[this.checkTalking.length - 1].convoIsTalking == null) {
        this.router.navigate([
          `/doctor/appointments-calendar/${this.id}`,
        ]);
      }
    });
  }

  alertChat(dataDigipay: any) {
    var ImgDoc = "assets/images/imgdoctor/nouser.svg";
    if (dataDigipay.UserImg != "") {
      ImgDoc = this.urlPath2021 + dataDigipay.UserImg;
    }
    Swal.fire({
      position: "center",
      title:
        this.datatranslate == "en"
          ? "Conversation Payment Status"
          : "สถานะชำระการสนทนา",
      html: `<img src='${ImgDoc}'style='object-fit: cover; width: 200px;height: 200px;border-radius:50%'>
      <h4 style='margin-top: 20px;margin-right: 10px;margin-left: 10px;'>${this.datatranslate == "en" ? "Payment status" : "สถานะการชำระ เลขที่"
        }<br>${dataDigipay.Reference}<br>${this.datatranslate == "en" ? "succeed" : "สำเร็จเรียบร้อย"
        }</h4>
      <h5 style='margin-top: 10px;margin-right: 10px;margin-left: 10px;'><b>${this.datatranslate == "en"
          ? "Please press start chat"
          : "กรุณากดเริ่มการสนทนา"
        }</b><br></h5>
        <h5>${this.datatranslate == "en"
          ? "to join the conversation with"
          : "เพื่อเข้าร่วมการสนทนากับ"
        } ${dataDigipay.Username}</h5>`,
      confirmButtonColor: "#ff7f50",
      confirmButtonText: "Start Chat",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true && this.roleLogin == "Doctor") {
        this.router.navigate([`/doctor/doctor-chats/chat`]);
      } else {
        this.router.navigate([`/nutritionist/nutritionist-chats/chat`]);
      }
    });
  }

  resetWindowsAndCloseTabs() {
    this.windows.forEach((element) => {
      element.close();
    });
    this.windows = [];
  }

  logout() {
    Swal.fire({
      title: this.datatranslate == "en" ? "Are you sure?" : "คุณแน่ใจไหม?",
      text:
        this.datatranslate == "en"
          ? "Do you want to log out?"
          : "คุณต้องการที่จะออกจากระบบ",
      imageUrl: "assets/images/logout/log-out.gif",
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: "#ff7f50",
      cancelButtonColor: "#FFFFFE",
      confirmButtonText: this.datatranslate == "en" ? "Yes" : "ใช่",
      cancelButtonText: this.datatranslate == "en" ? "No" : "ไม่",
      allowOutsideClick: false,
      didOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        const cancelButton = Swal.getCancelButton();

        confirmButton.style.padding = '10px 60px';
        confirmButton.style.fontSize = '16px';
        confirmButton.style.borderRadius = '10px'; // ใช้ border-radius ตรงนี้

        cancelButton.style.padding = '10px 60px';
        cancelButton.style.fontSize = '16px';
        cancelButton.style.borderRadius = '10px'; // ใช้ border-radius ตรงนี้
        cancelButton.style.border = '2px solid rgba(0,0,0,.5)'; // กรอบสีดำให้ปุ่ม No
        cancelButton.style.color = 'black'; // เปลี่ยนสีตัวหนังสือปุ่ม No เป็นสีดำ
      }
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.logOutNow();
      } else {
        console.log("error : ");
      }
    });
  }

  logOutNow() {
    if (this.roleLogin == "Doctor") {
      if (this.dataGetTimeWork != null) {
        this.idWork = this.dataGetTimeWork.worK_Id;
      }
    } else if (this.roleLogin == "Nutritionist") {
      if (this.getTimeWorkNutritionistList != null) {
        this.idWork = this.getTimeWorkNutritionistList.worK_Id;
      }
    }
    if (this.nameDoctor != "AdminEp&it") {
      // this.authService.logout(this.nameDoctor, false, this.roleLogin).then(resLogout => {
      //   if (resLogout == "success") {
      var dataLocal = localStorage.getItem("currentUser");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("onesignal-allow");
      localStorage.removeItem("showDialogWorkTime");
      localStorage.removeItem("referenceDigipay");
      localStorage.removeItem("NewOrder");
      this.workTimeService
        .DeleteWorkByDrId_(this.idWork)
        .then((resDeleteTime) => {
          // console.log("--->", resDeleteTime);

          this.timerCountDown.unsubscribe();

        });
      this.workTimeService
        .DeleteWorkByNutritionistId_(this.idWork)
        .then((resDeleteTime) => {
          this.timerCountDown.unsubscribe();

        });
      // this.router
      //   .navigate([`/authentication/signout/${this.id}`], {
      //     replaceUrl: true,
      //   })
      //   .then(() => {
      //     window.location.reload();
      //   });
      // this.router.navigate([`/authentication/signin`], { replaceUrl: true }).then(() => { window.location.reload(); });
      //   }
      // });

      if (dataLocal != null) {
        var data_ = JSON.parse(dataLocal);
        if (data_.role != "Dealer") {
          this.router
            .navigate([`/authentication/signin`], { replaceUrl: true })
            .then(() => {
              window.location.reload();
            });
        } else {
          this.router
            .navigate([`/authentication/signin-dealer`], {
              replaceUrl: true,
            })
            .then(() => {
              window.location.reload();
            });
        }
      } else {
        this.router
          .navigate([`/authentication/signin`], { replaceUrl: true })
          .then(() => {
            window.location.reload();
          });
      }
    } else {
      localStorage.removeItem("showDialogWorkTime");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("onesignal-allow");
      localStorage.removeItem("referenceDigipay");
      localStorage.removeItem("NewOrder");
      this.workTimeService
        .DeleteWorkByDrId_(this.idWork)
        .then((resDeleteTime) => {
          this.timerCountDown.unsubscribe();

        });
      this.workTimeService
        .DeleteWorkByNutritionistId_(this.idWork)
        .then((resDeleteTime) => {
          this.timerCountDown.unsubscribe();

        });
      this.router
        .navigate([`/authentication/signin`], { replaceUrl: true })
        .then(() => {
          window.location.reload();
        });
    }
  }

  getTimeWork(idDoc: any) {
    this.workTimeService.GetWorkByDrId_(idDoc).then((resAddTime) => {
      this.dataGetTimeWork = resAddTime;
      if (this.dataGetTimeWork != null && this.dataGetTimeWork != undefined) {
        if (new Date() < new Date(this.dataGetTimeWork.worK_time_end)) {
          this.isChecked = true;

          this.checkCountDownTime(this.dataGetTimeWork);
        } else {
          this.isChecked = false;
          this.workTimeService
            .DeleteWorkByDrId_(this.dataGetTimeWork.worK_Id)
            .then((resDeleteTime) => { });
        }
      } else {
        // localStorage.removeItem("showDialogWorkTime");
        var dialogTime = localStorage.getItem("showDialogWorkTime");
        if (
          this.changePass == true &&
          dialogTime == null &&
          this.roleLogin == "Doctor"
        ) {
          setTimeout(() => {
            this.openDialogTimeCountdownWorking();
            localStorage.setItem("showDialogWorkTime", "0");
          }, 3000);
        }
      }
    });
  }

  getTimeWorkNutritionist(idNut: string) {
    this.workTimeService.GetWorkByNutritionistId_(idNut).then((res) => {
      var success: any = res;
      this.getTimeWorkNutritionistList = success.data;
      // console.log(this.getTimeWorkNutritionistList);

      if (
        this.getTimeWorkNutritionistList != null &&
        this.getTimeWorkNutritionistList != undefined
      ) {
        // console.log(new Date(),new Date(this.getTimeWorkNutritionistList.worK_time_end));
        if (
          new Date() < new Date(this.getTimeWorkNutritionistList.worK_time_end)
        ) {
          this.isCheckedNut = true;
          this.checkCountDownTime(this.getTimeWorkNutritionistList);
        } else {
          this.isCheckedNut = false;
          this.workTimeService
            .DeleteWorkByNutritionistId_(
              this.getTimeWorkNutritionistList.worK_Id
            )
            .then((resDeleteTime) => { });
        }
      } else {
        // localStorage.removeItem("showDialogWorkTime");
        var dialogTime = localStorage.getItem("showDialogWorkTime");
        if (
          this.changePass == true &&
          dialogTime == null &&
          this.roleLogin == "Nutritionist"
        ) {
          setTimeout(() => {
            this.openDialogTimeCountdownWorking();
            localStorage.setItem("showDialogWorkTime", "0");
          }, 3000);
        }
      }
    });
  }

  checkCountDownTime(data_: any) {
    this.timeCount = data_.worK_time_end;

    this.countDownTime();
    this.timerCountDown = interval(1000).subscribe((x) => {
      this.countDownTime();
    });
  }

  async checkChangPassword() {
    if (this.changePass != true) {
      setTimeout(() => {
        this.openDialogChangPassword();
      }, 1000);
    }
  }

  countDownTime() {
    var now = new Date();
    var timeOut = new Date(this.timeCount);

    var date__ = timeOut.getTime() - now.getTime();
    // var countdown = new Date(date__);
    // var time = countdown.setHours(countdown.getHours() - 7);
    var countdownNow = moment(date__).format("hh:mm:ss");
    // console.log(countdownNow);

    if (countdownNow == "07:00:00") {
      this.timerCountDown.unsubscribe();
    }
    if (countdownNow == "07:03:00") {
      this.openDialogCountdown();
    }
    if (countdownNow == "07:00:00") {
      setTimeout(() => {
        this.openDialogTimeOut();
      }, 500);
    }
  }

  openDialogTimeStartWorking(status?: any) {
    localStorage.setItem("showDialogWorkTime", "0");
    var dialogRef: any;
    if (status == "update") {
      dialogRef = this.dialog.open(TimeStartDialogComponent, {
        panelClass: "custom-modalbox",
        data: {
          dR_Id:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.dR_Id
              : this.getTimeWorkNutritionistList.nttN_Id,
          worK_Id:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.worK_Id
              : this.getTimeWorkNutritionistList.worK_Id,
          worK_Status:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.worK_Status
              : this.getTimeWorkNutritionistList.worK_Status,
          worK_time_start:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.worK_time_start
              : this.getTimeWorkNutritionistList.worK_time_start,
          worK_time_end:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.worK_time_end
              : this.getTimeWorkNutritionistList.worK_time_end,
          worK_Date:
            this.roleLogin == "Doctor"
              ? this.dataGetTimeWork.worK_Date
              : this.getTimeWorkNutritionistList.worK_Date,
          statusUpdate: status,
        },
      });
    } else {
      dialogRef = this.dialog.open(TimeStartDialogComponent, {
        panelClass: "custom-modalbox",
        data: {
          statusUpdate: status,
        },
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result.type == "add") {
          if (this.roleLogin == "Doctor") {
            this.dataGetTimeWork = {
              dR_Id: result.dR_Id,
              worK_Id: result.worK_Id,
              worK_Status: result.worK_Status,
              worK_time_start: result.worK_time_start,
              worK_time_end: result.worK_time_end,
              worK_Date: new Date(),
            };
          } else if (this.roleLogin == "Nutritionist") {
            this.getTimeWorkNutritionistList = {
              nttN_Id: result.nttN_Id,
              worK_Id: result.worK_Id,
              worK_Status: result.worK_Status,
              worK_time_start: result.worK_time_start,
              worK_time_end: result.worK_time_end,
              worK_Date: new Date(),
            };
          }
          this.isChecked = result.worK_Status;
          this.isCheckedNut = result.worK_Status;
          this.checkCountDownTime(result.dataResule);
        } else if (result.type == "update") {
          this.roleLogin == "Doctor"
            ? (this.dataGetTimeWork.worK_Status = result.worK_Status)
            : (this.getTimeWorkNutritionistList.worK_Status =
              result.worK_Status);
          this.roleLogin == "Doctor"
            ? (this.dataGetTimeWork.worK_time_start = result.worK_time_start)
            : (this.getTimeWorkNutritionistList.worK_time_start =
              result.worK_time_start);
          this.roleLogin == "Doctor"
            ? (this.dataGetTimeWork.worK_time_end = result.worK_time_end)
            : (this.getTimeWorkNutritionistList.worK_time_end =
              result.worK_time_end);

          this.timerCountDown.unsubscribe();
          this.isChecked = result.worK_Status;
          this.isCheckedNut = result.worK_Status;
          this.checkCountDownTime(result.dataResule);
        } else if (result == false) {
          // this.roleLogin == "Doctor"
          //   ? this.dataGetTimeWork.worK_Status = false
          //   : this.getTimeWorkNutritionistList.worK_Status = false

          this.isChecked = false;
          this.isCheckedNut = false;
          this.timerCountDown.unsubscribe();
        }
      }
    });
  }

  getElapsedTime(entry: any) {
    var today = new Date(entry);
    today.setHours(today.getHours() - 7);

    var date__ = today.getTime() - new Date().getTime();

    var countdown = moment(date__).format("HH:mm:ss");
    if (countdown == "00:00:00") {
      this.isChecked = false;
      this.isCheckedNut = false;
      clearInterval(this.timerCountDown);
    }
    return countdown;
  }
  ngOnDestroy() {
    if (this.timerCountDown) {
      clearInterval(this.timerCountDown);
    }
  }

  openDialogTimeCountdownWorking() {
    Swal.fire({
      title:
        this.datatranslate == "en"
          ? "Want to write down your working hours?"
          : "ต้องการเปิดการทำงานของคุณหรือไม่?",
      text: `${this.datatranslate == "en"
        ? "Press OK to enter the working hours?"
        : "กด ตกลง เพื่อเข้าสู่เวลาการทำการ?"
        }`,
      imageUrl: "assets/images/digit-w.gif",
      imageWidth: 150,
      showCancelButton: true,
      confirmButtonColor: "#ed8f03",
      cancelButtonColor: "#bdc3c7",
      confirmButtonText: this.datatranslate == "en" ? "OK" : "ตกลง",
      cancelButtonText: this.datatranslate == "en" ? "Cancel" : "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.openDialogTimeStartWorking("start");
      } else {
        localStorage.setItem("showDialogWorkTime", "0");
      }
    });
  }

  openDialogCountdown() {
    Swal.fire({
      title:
        this.datatranslate == "en"
          ? "<strong>Notification of time attendance</strong>"
          : "<strong>แจ้งการลงเวลา</strong>",
      html:
        this.datatranslate == "en"
          ? "Remaining time of your work <b>3</b> minute"
          : "เวลาที่เหลือในการทำงานของคุณ <b>3</b> นาที",
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: this.datatranslate == "en" ? "confirm" : "ยืนยัน",
      imageUrl: "assets/images/countdown.gif",
      imageWidth: 200,
      showCancelButton: true,
      confirmButtonColor: "#ed8f03",
      cancelButtonColor: "#bdc3c7",
      cancelButtonText: this.datatranslate == "en" ? "cancel" : "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.openDialogTimeStartWorking("update");
      } else {
        console.log("error : ");
      }
    });
  }

  openDialogTimeOut() {
    Swal.fire({
      title: this.datatranslate == "en" ? "Timeout!" : "หมดเวลา!",
      text:
        this.datatranslate == "en"
          ? "The working time has expired."
          : "หมดเวลาทำงานแล้ว",
      imageUrl: "assets/images/timeout.gif",
      imageWidth: 200,
      allowOutsideClick: false,
      confirmButtonColor: "#ed8f03",
    }).then((result) => {
      if (result.isConfirmed == true && this.roleLogin == "Doctor") {
        this.isChecked = false;
        this.workTimeService
          .DeleteWorkByDrId_(this.dataGetTimeWork.worK_Id)
          .then((resDeleteTime) => { });
      } else if (
        result.isConfirmed == true &&
        this.roleLogin == "Nutritionist"
      ) {
        this.isCheckedNut = false;
        this.workTimeService
          .DeleteWorkByNutritionistId_(this.getTimeWorkNutritionistList.worK_Id)
          .then((resDeleteTime) => { });
      }
    });
  }

  openDialogChangPassword() {
    const dialogRef = this.dialog.open(ChangPasswordDialogComponent, {
      disableClose: true,
      // data: {
      //   doctors: row,
      //   action: "edit",
      // },
      // width: "70%",
      // height: "70%"
    });
    dialogRef.afterClosed().subscribe((result) => {
      // var dataJson: any = [];
      if (result) {
        var dataJson = {
          changePassStatus: true,
          id: this.data_JsonToken.id,
          img: this.data_JsonToken.img,
          role: this.data_JsonToken.role,
          status: this.data_JsonToken.status,
          token: this.data_JsonToken.token,
          username: this.data_JsonToken.username,
        };
        localStorage.setItem("currentUser", JSON.stringify(dataJson));
        this.openDialogTimeStartWorking("start");
      }
    });
  }

  playAudioOrder() {
    // let audio = new Audio();
    // audio.src = "assets/sound/mixkit-clear-announce-tones-2861.wav";
    // audio.load();
    // audio.play();
    const playSound = (audioURL, playCount = 1) => {

      const audioContext = new AudioContext();
      audioContext.resume();

      let playCountArray = [];

      for (let i = 0; i < playCount; i++) {

        let soundInstance = new Audio(audioURL);
        playCountArray.push(soundInstance);

        if (playCountArray.length < playCount) {

          playCountArray[i].addEventListener('ended', () => { playCountArray.shift(); playCountArray[0].play(); });
        }
      }

      playCountArray[0].play();
    };


    playSound('assets/sound/mixkit-clear-announce-tones-2861.wav', 3);
  }

  playAudioChat() {
    let audio = new Audio();
    audio.src = "assets/sound/mixkit-correct-answer-tone-2870.wav";
    audio.load();
    audio.play();
  }

  idleLoad() {
    this.idle.setIdle(3600);
    this.idle.setTimeout(3);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logOutNow();
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.resetIdle();
  }

  resetIdle() {
    this.idle.watch();
    this.timedOut = false;
  }
  alertWaitingForPayment() {
    Swal.fire({
      title: this.datatranslate == "en" ? "waiting for payment!" : "รอชำระเงิน!",
      imageUrl: 'assets/images/loading/wait-load-4.gif',
      text: this.datatranslate == "en" ? "The patient is paying to continue the conversation. Please close the old call page and start a new call after the payment is complete." : "ผู้ป่วยกำลังทำการชำระเงินเพื่อสนทนาต่อกรุณาปิดหน้าคอลเดิมแล้วทำการเริ่มคอลใหม่หลังการชำระเงินเสร็จสิ้น",
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }
}
